import { TTask, TTaskId } from '../types';

/**
 * Возвращает следующий урок, учитывая историю пройденных уроков.
 * Если все уроки пройдены, выбирает из всех, исключая последние N.
 * @param allTasks - Массив всех доступных уроков.
 * @param alreadySelectedTaskIds - Массив ID уже пройденных уроков, упорядоченных по времени прохождения (старые первыми).
 * @param historySize - Количество последних уроков, которые нужно исключить при повторе.
 * @returns Следующий выбранный урок.
 */
export const getNextTaskWhichWasNotSelectedYet = (
  allTasks: TTask[],
  alreadySelectedTaskIds: TTaskId[],
  historySize: number = 5 // По умолчанию исключаем последние 5 уроков
): TTask | null => {
  if (!allTasks.length) {
    return null; // Нет доступных уроков
  }

  const totalTasks = allTasks.length;
  const completedTasksSet = new Set(alreadySelectedTaskIds);

  // Проверяем, прошел ли пользователь все уроки
  const allCompleted = completedTasksSet.size >= totalTasks;

  if (!allCompleted) {
    // Выбираем из непройденных уроков
    const remainingTasks = allTasks.filter(
      (task) => !completedTasksSet.has(task.id)
    );
    if (remainingTasks.length === 0) {
      // Все уроки пройдены, переходим к повтору
      return selectFromAllTasksExcludingHistory(
        allTasks,
        alreadySelectedTaskIds,
        historySize
      );
    }
    // Случайный выбор из оставшихся
    const randomIndex = Math.floor(Math.random() * remainingTasks.length);
    return remainingTasks[randomIndex];
  } else {
    // Все уроки пройдены, выбираем из всех, исключая последние N
    return selectFromAllTasksExcludingHistory(
      allTasks,
      alreadySelectedTaskIds,
      historySize
    );
  }
};

/**
 * Выбирает случайный урок из всех уроков, исключая последние N из истории.
 * Если невозможно исключить все последние N, уменьшает размер окна.
 * @param allTasks - Массив всех доступных уроков.
 * @param alreadySelectedTaskIds - Массив ID уже пройденных уроков, упорядоченных по времени прохождения (старые первыми).
 * @param historySize - Максимальный размер окна истории.
 * @returns Выбранный урок или null, если выбор невозможен.
 */
const selectFromAllTasksExcludingHistory = (
  allTasks: TTask[],
  alreadySelectedTaskIds: TTaskId[],
  historySize: number
): TTask | null => {
  for (let n = historySize; n >= 0; n--) {
    const history = alreadySelectedTaskIds.slice(-n);
    const historySet = new Set(history);

    const availableTasks = allTasks.filter((task) => !historySet.has(task.id));

    if (availableTasks.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableTasks.length);
      return availableTasks[randomIndex];
    }
    // Если нет доступных задач при текущем n, уменьшаем n и пробуем снова
  }
  // Если невозможно выбрать без исключений, возвращаем случайный урок
  const randomIndex = Math.floor(Math.random() * allTasks.length);
  return allTasks[randomIndex];
};
