import { Scores } from '../features/Scores';
import React from 'react';
import styled from 'styled-components';
import { useGame } from '../hooks/useGame';

type TUseGameProps = ReturnType<typeof useGame>;

type TProps = {
  currentSentence: TUseGameProps['currentSentence'];
  lessonStat: TUseGameProps['lessonStat'];
  isOnlyButtonMode: TUseGameProps['isOnlyButtonMode'];
  userSentenceStr: TUseGameProps['userSentenceStr'];
};

export const ConditionAndAnswer = ({
  currentSentence,
  lessonStat,
  isOnlyButtonMode,
  userSentenceStr,
}: TProps) => {
  const getAnswerPlaceholder = () => {
    if (!userSentenceStr || userSentenceStr === '_') {
      return '';
    }

    return userSentenceStr.replace(/_+/g, '____');
  };

  return (
    <>
      <SCondition>{currentSentence?.native}</SCondition>
      <SWrap>
        <SLeft>
          {currentSentence?.extra && (
            <SConditionExtra>({currentSentence?.extra})</SConditionExtra>
          )}

          {!isOnlyButtonMode && <SAnswer>{getAnswerPlaceholder()}</SAnswer>}
        </SLeft>
        <SRight>
          <Scores lessonStat={lessonStat} />
        </SRight>
      </SWrap>
    </>
  );
};

const SCondition = styled.div`
  font-weight: 700;
  margin-bottom: 10px;

  color: #000;
  font-size: 20px;
  font-style: normal;
  line-height: 130%; /* 18.2px */
`;

const SConditionExtra = styled(SCondition)`
  font-size: 12px;
`;

const SAnswer = styled.div`
  color: var(--polako-black);
  border: 1px solid #000;
  min-height: 100px;
  background-color: white;
  display: flex;
  padding: 14px 20px;
  gap: 10px;
  box-shadow: var(--polako-shadow);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
`;

const SWrap = styled.div`
  display: flex;
  gap: 20px;
`;

const SLeft = styled.div`
  flex: 1;
`;

const SRight = styled.div``;
