import { removeItemFromArr } from '../../../utils/removeItemFromArr';
import { useState } from 'react';
import { TModeRef, TTask } from '../types';
import { checkHowMuchGaps } from '../utils/checkHowMuchGaps';
import { getIsOnlyOneGapMode } from '../utils/getIsOnlyOneGapMode';
// import { Haptics, ImpactStyle } from '@capacitor/haptics';
type TProps = {
  currentTask: TTask | null;
  mode: TModeRef;
};

export const usePressOnWord = ({ currentTask, mode }: TProps) => {
  const [alreadyPressedIndexes, setAlreadyPressedIndexes] = useState<number[]>(
    []
  );
  const [userSelectedWordsArr, setUserSelectedWordsArr] = useState<string[]>(
    []
  );

  const addNewWordToSelectedList = (word: string, index: number) => {
    setUserSelectedWordsArr((words) => {
      return [...words, word];
    });

    setAlreadyPressedIndexes((indexes) => {
      return [...indexes, index];
    });
  };

  const checkIfCanAddPartModeWord = () => {
    if (!currentTask) return;
    const maximumWords = checkHowMuchGaps(currentTask.foreign);

    const wordsPressedNow = alreadyPressedIndexes.length + 1;
    return !(wordsPressedNow > maximumWords);
  };

  const handlePartModeWordClick = (word: string, index: number) => {
    if (getIsOnlyOneGapMode(currentTask)) {
      setUserSelectedWordsArr([word]);
      setAlreadyPressedIndexes([index]);
      return;
    }

    if (checkIfCanAddPartModeWord()) {
      addNewWordToSelectedList(word, index);
    }
  };

  const wordClickHandler = async (
    word: string,
    isPressed: boolean,
    index: number
  ) => {
    // await Haptics.impact({ style: ImpactStyle.Light });

    if (isPressed) {
      setUserSelectedWordsArr([
        ...removeItemFromArr(userSelectedWordsArr, word),
      ]);
      setAlreadyPressedIndexes([
        ...removeItemFromArr(alreadyPressedIndexes, index),
      ]);
      return;
    }

    if (mode.current === 'full') {
      return addNewWordToSelectedList(word, index);
    }

    return handlePartModeWordClick(word, index);
  };

  const resetClickedWords = () => {
    setUserSelectedWordsArr([]);
    setAlreadyPressedIndexes([]);
  };

  const clearLastSelectedWord = () => {
    setUserSelectedWordsArr(removeLastElem(userSelectedWordsArr));
    setAlreadyPressedIndexes(removeLastElem(alreadyPressedIndexes));
  };

  return {
    wordClickHandler,
    resetClickedWords,
    userSelectedWordsArr,
    alreadyPressedIndexes,
    clearLastSelectedWord,
  };
};

const removeLastElem = <T>(arr: T[]): T[] => {
  const copy = [...arr];
  copy.pop();
  return copy;
};
