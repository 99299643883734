import React, { FC } from 'react';
import styled from 'styled-components';
// import { ReactComponent as Heart3 } from '../../assets/images/heart3.svg';
// import { ReactComponent as Heart4 } from '../../assets/images/heart4.svg';
import { SDays } from '../../pages/Intro/styles';
import { Modal } from '../Modal/Modal';
import { WhyPaid } from './WhyPaid';
import { PromoCats } from './PromoCats';
import { PricesAndBuyButton } from './PricesAndBuyButton';

type TProps = { close: () => void };

export const SubscriptionModalInner: FC<TProps> = ({ close }) => {
  return (
    <ModalStyled close={close}>
      <SWrapper>
        <SKletka />
        <SHeaderTexts>
          <SHeader1>Подписка</SHeader1>
          <SHeader2>на уроки</SHeader2>
        </SHeaderTexts>

        <SHeaderCats>
          <WhyPaid />

          <PromoCats />
        </SHeaderCats>

        <PricesAndBuyButton />
      </SWrapper>
    </ModalStyled>
  );
};

// const Heart3Styled = styled(Heart3)`
//   position: absolute;
//   top: -24px;
//   right: 80px;
// `;
//
// const Heart4Styled = styled(Heart4)`
//   position: absolute;
//   top: -24px;
//   right: 80px;
// `;

const ModalStyled = styled(Modal)`
  padding: 0;
  background-color: var(--polako-purple);
`;

const SWrapper = styled.div`
  inset: 0;
`;

export const SKletka = styled.div`
  pointer-events: none;
  position: absolute;
  inset: 0;

  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.1) 2px,
      transparent 1px
    ),
    linear-gradient(180deg, rgba(255, 255, 255, 0.1) 2px, transparent 1px);
  background-size: 25px 25px; /* Размер клетки */
`;

const SHeaderCats = styled.div`
  position: relative;
  overflow-x: clip;
`;

const SHeaderTexts = styled(SDays)`
  padding: 11px;
  text-shadow:
    -1px -1px 0 black,
    0px -0.5px 0 black,
    -1px 1px 0 black,
    0.6px 0.6px 0 black,
    2px 2px 0px var(--polako-yellow),
    3px 3px 0px var(--polako-black);
`;

const SHeader1 = styled.div`
  font-size: 26px;
`;

const SHeader2 = styled.div`
  font-size: 16px;
`;
