import { BILLING } from '../../../constants';
import { Clipboard } from '@capacitor/clipboard';
import { isDevelopmentFeaturesEnabled } from '../../../utils/isDevelopmentFeaturesEnabled';
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { useShowCatForMsNew } from '../../Game/components/EncouragingCat2/useShowCatForMsNew';
import { useAsync } from 'react-use';
import { App } from '@capacitor/app';
import { useRuntimeStore } from '../../../initApp';

export const BottomTechBlock = () => {
  const { user } = useRuntimeStore();

  const { showCat } = useShowCatForMsNew();
  const appVersion = useAsync(async () => {
    const resp = await App.getInfo();
    return resp.version;
  }, []);

  const tgText = `MyID: ${user?.uid}`;

  return (
    <STerms>
      <SBottomLeft>
        <div>
          <a href="https://polako.app/terms">Terms & Conditions</a>
        </div>
        <br />
        <div>
          <a href="https://polako.app/privacy-policy">Privacy Policy</a>
        </div>
        <br />

        <span> Billing: {BILLING}</span>
        <br />
        <span>
          <span
            onClick={async () => {
              try {
                await Clipboard.write({
                  string: user?.uid,
                });
                showCat({
                  text: 'Скопировал!',
                });
              } catch (e) {
                showCat({
                  text: 'Не получилось скопировать(',
                  textSub: 'Скопируй плз вручную',
                });
              }
            }}
          >
            Ваш ID: {user?.uid}
          </span>
          <br />
          Версия полаки: {appVersion.loading ? 'loading...' : appVersion.value}
          <br />
          <br />
          <ButtonCopy
            size={'s'}
            text={'Скопировать ваш ID'}
            onClick={async () => {
              try {
                await Clipboard.write({
                  string: user?.uid,
                });
                showCat({
                  text: 'Скопировал!',
                });
              } catch (e) {
                showCat({
                  text: 'Не получилось скопировать(',
                  textSub: 'Скопируй плз вручную',
                });
              }
            }}
            square={false}
          />
          <br />
          <br />
          <ButtonCopy
            onClick={async () => {
              window.open('https://t.me/polakoapp?text=' + tgText);
            }}
            size={'s'}
            text={'Отправить ID в телеграм разработчику'}
            square={false}
          />
        </span>
      </SBottomLeft>

      <div>{isDevelopmentFeaturesEnabled() ? 'development' : 'production'}</div>
    </STerms>
  );
};

const SBottomLeft = styled.div`
  flex: 1;
`;

const ButtonCopy = styled(Button)`
  &::part(native) {
    padding: 3px;
  }
`;
const STerms = styled.div`
  font-weight: 700;
  font-size: 8px;
  padding-bottom: 20px;

  display: flex;
  justify-content: space-between;
`;
