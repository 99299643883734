import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useShowCatForMsNew } from '../../../pages/Game/components/EncouragingCat2/useShowCatForMsNew';
import { useIOSSetAccessToAllPermissions } from '../../../hooks/billing/useIOSSetAccessToAllPermissions';
import { Billing } from '../../../biling/Billing';
import { TPolakoSku } from '../../../types';

type TProps = {
  close: () => void;
};

export const useSubscribeModal = ({ close }: TProps) => {
  const { showCat } = useShowCatForMsNew();
  const [selectedSku, setSelectedSku] = useState<TPolakoSku | null>(null);
  const [allSkus, setAllSkus] = useState<TPolakoSku[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [successResult, setSuccessResult] = useState(false);
  const [storeError, setStoreError] = useState(false);

  const { setBillingAccessToAllPermissions: setAccessIOS } =
    useIOSSetAccessToAllPermissions();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const skus = await Billing.getSkus();

        if (!skus) {
          setStoreError(true);
          throw new Error('No skus found');
        }

        // console.log('IOS skus', skus);

        setAllSkus(skus);
        setSelectedSku(skus[0]);
      } catch (e) {
        console.error(e);

        Sentry.captureException(e);

        // showCat({
        //   text: 'Не получилось загрузить цены, сорри.',
        //   textSub: 'Какая-то фигня :(',
        // });
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const buy = async () => {
    if (!selectedSku) return;

    try {
      setIsLoading(true);

      const permission = await Billing.purchaseSku(selectedSku);

      setAccessIOS(permission);

      setSuccessResult(true);
      setIsLoading(false);
      close();
    } catch (e) {
      showCat({
        text: 'Не получилось оплатить',
        textSub: 'Какая-то фигня :(',
      });

      console.error(e);
      setIsLoading(false);
      Sentry.captureException(e);
    }
  };

  return {
    allSkus,
    isLoading,
    successResult,
    selectedSku,
    setSelectedSku,
    buy,
    storeError,
  };
};
