import { TStorageLesson } from './pages/Game/types';
import { TBilling, TBlockReason } from './types';

export const MINIMUM_SCORE = 1;
export const MAXIMUM_SCORE = 5;
export const DEFAULT_LESSON: TStorageLesson = {
  score: MINIMUM_SCORE,
  blocked: true,
  correct: 0,
  wrong: 0,
  giveUps: 0,
  solvedIds: [],
  time: 0,
};

export const DEFAULT_LESSON_FIRST: TStorageLesson = {
  ...DEFAULT_LESSON,
  blocked: false,
};
export const SHOW_CAT_EVERY_CORRECT_ANSWER = 5;
export const LESSON_CONSIDERED_PASSED = 3;
export const CAT_ON_SCREEN = 3500;
export const LESSONS_BLOCKED_AFTER = 3;
export const HOW_MUCH_DEMO_LESSONS_AVAILABLE = 3;
export const ASK_FOR_NOTIFICATIONS_AFTER_SOLVED = 10;

export const BLOCK_REASONS: Record<TBlockReason, string> = {
  notReleasedYet: `Ты молодец, что дошел до этого урока! Но он появится позже 😉`,
  internalError: 'Ошибка загрузки данных',
  subscription: 'Требуется подписка',
  prevLessonNotPassed: `Сначала пройди предыдущий урок минимум на ${LESSON_CONSIDERED_PASSED} балла`,
};

export const COLORS = {
  default: {
    hex: '#FF6040',
    rgba: 'rgba(255, 96, 64, 1)',
  },
  pink: {
    hex: '#FFD4CC',
    rgba: 'rgba(255, 212, 204, 1)',
  },
  yellow: {
    hex: '#FFECA8',
    rgba: 'rgba(255, 236, 168, 1)',
  },
  white: {
    hex: '#FFFFFF',
    rgba: 'rgba(255, 255, 255, 1)',
  },
} as const;

export const BRAND_COLOR = '#FF6040';
export const SUBSCRIPTIONS_ENABLED = true;
// export const SUBSCRIPTIONS_ENABLED = Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'web';

export const BILLING: TBilling = 'revenuecat';

export const REVENUE_CAT_KEY = {
  ios: 'appl_UlfbpBSCzqGvNerPiPEsWmvvDOt',
  android: 'goog_VxSkgCKIAPsZnjnlkrOUFMLGydT',
};
