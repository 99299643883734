import styled, { css } from 'styled-components';
import CatFace from '../../../../assets/images/cat-face.svg';
import { useShowCatForMsNew } from '../EncouragingCat2/useShowCatForMsNew';

interface ProgressProps {
  score: number;
  totalSteps?: number;
}

const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 17px 10px 17px;
`;

const Track = styled.div`
  position: relative;
  height: 4px;
  background: #00000020;
`;

const CompletedTrack = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #000000;
  width: ${(props) => props.progress}%;
  transition: width 0.3s ease;
`;

const CheckpointsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
`;

const Checkpoint = styled.div<{
  dimmed: boolean;
  color: string;
  big?: boolean;
}>`
  width: 15px;
  height: 15px;
  background-color: ${(props) =>
    props.dimmed && !props.big ? `rgb(133, 60, 45)` : props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color === '#000000' ? 'white' : 'black')};
  font-weight: bold;
  font-size: 10px;
  transition: opacity 0.3s ease;
  position: relative;
  top: 1px;

  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    right: -4px;
    z-index: 1;
  }

  ${({ big }) => {
    return big
      ? css`
          box-shadow: var(--polako-shadow);
          opacity: 1;
          border: 1px solid black;
          width: 18px;
          height: 18px;
          position: relative;
          top: -1px;
        `
      : '';
  }}
`;

const CatIcon = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: ${(props) => props.progress}%;
  transform: translate(-32%, -37%);
  width: 40px;
  height: 40px;
  transition: left 0.3s ease;
  z-index: 1;
`;

const SScore = styled.div`
  font-size: 8px;
  font-weight: 700;
  text-align: center;
  position: relative;
  top: -5px;
`;

const checkpointColors = [
  '#000000',
  '#000000',
  'var(--polako-purple)',
  '#000000',
  '#000000',
];

const texts = {
  '1': 'Тут ты начал, когда еще не шарил в этой теме',
  '2': '2 балла – лучше чем 1, но надо хотя бы 3',
  '3': 'Дойдешь – открою следующий урок',
  '4': 'Дойдешь – буду гордиться тобой, но не до конца',
  '5': 'Дойдешь – буду гордиться тобой полностью!',
};

export default function CatProgress({ score, totalSteps = 5 }: ProgressProps) {
  const { showCat } = useShowCatForMsNew();
  // Convert score to percentage (0-100)
  const progress = ((score - 1) / (totalSteps - 1)) * 100;

  return (
    <ProgressContainer>
      <Track>
        <CompletedTrack progress={progress}></CompletedTrack>
        <CatIcon
          progress={progress}
          onClick={() => {
            showCat({
              text: `Сколько у тебя баллов`,
              textSub: `${score} из ${totalSteps}`,
            });
          }}
        >
          <CatFace />
          <SScore>{score}</SScore>
        </CatIcon>
        <CheckpointsContainer>
          {Array.from({ length: totalSteps }, (_, i) => (
            <Checkpoint
              key={i}
              dimmed={i + 1 > Math.floor(score)}
              color={checkpointColors[i]}
              onClick={() => {
                showCat({
                  // @ts-ignore
                  text: texts[i + 1 + ''] || '',
                });
              }}
              big={i + 1 === 3}
            >
              {i + 1}
            </Checkpoint>
          ))}
        </CheckpointsContainer>
      </Track>
    </ProgressContainer>
  );
}
