import { useRuntimeStore } from '../../initApp';
import type { User } from '@capacitor-firebase/authentication/dist/esm/definitions';
import { Capacitor } from '@capacitor/core';
import { Billing } from '../../biling/Billing';

export const useSetUserAndConnectBilling = () => {
  const setUser = useRuntimeStore((s) => s.setUser);

  const setUserAndConnectBilling = async (user: User | null) => {
    setUser(user);

    if (
      Capacitor.getPlatform() === 'ios' ||
      Capacitor.getPlatform() === 'android'
    ) {
      await Billing.connectCustomSubscriber(user?.uid || '');
    }
  };

  return { setUserAndConnectBilling: setUserAndConnectBilling };
};
