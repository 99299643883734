import { useIonLoading } from '@ionic/react';

export const useThink = () => {
  const [present, dismiss] = useIonLoading();

  return {
    showThinking: async () => {
      await present({ message: 'Думаю', cssClass: 'custom-loading' });
    },

    hideThinking: async () => {
      await dismiss();
    },
  };
};
