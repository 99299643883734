import { Button } from '../../../components/Button';
import { MainButton } from './MainButton';
import React from 'react';
import styled from 'styled-components';
import iconPlay from './../../../assets/icons/play.svg?inline';
import iconBulb from './../../../assets/icons/bulb.svg?inline';
import iconBackspace from './../../../assets/icons/backspace.svg?inline';
import { useGame } from '../hooks/useGame';

type TUseGameProps = ReturnType<typeof useGame>;

type TProps = {
  isCorrect: TUseGameProps['isCorrect'];
  userSelectedWordsArr: TUseGameProps['userSelectedWordsArr'];
  readyClickHandler: TUseGameProps['readyClickHandler'];
  nextClickHandler: TUseGameProps['nextClickHandler'];
  undoClickHandler: TUseGameProps['undoClickHandler'];
  showTipClickHandler: TUseGameProps['showTipClickHandler'];
  playSound: TUseGameProps['playSound'];
};

export const GameFooter = ({
  isCorrect,
  userSelectedWordsArr,
  readyClickHandler,
  nextClickHandler,
  undoClickHandler,
  showTipClickHandler,
  playSound,
}: TProps) => {
  return (
    <SMainButtons>
      <Button
        size={'l'}
        onClick={showTipClickHandler}
        color={'yellow'}
        icon={iconBulb}
        fontSize={30}
        square
      />

      <SMainButton>
        {isCorrect && (
          <Button
            size={'l'}
            onClick={() => {
              playSound();
            }}
            color={'yellow'}
            icon={iconPlay}
            fontSize={24}
            square
          />
        )}
        <MainButton
          isCorrect={isCorrect}
          userSelectedWordsArr={userSelectedWordsArr}
          readyClickHandler={readyClickHandler}
          handleNext={nextClickHandler}
        />
      </SMainButton>

      <Button
        size={'l'}
        onClick={undoClickHandler}
        color={'yellow'}
        icon={iconBackspace}
        fontSize={30}
        square
      />
    </SMainButtons>
  );
};

const SMainButtons = styled.div`
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  margin-top: 16px;
`;

const SMainButton = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
`;
