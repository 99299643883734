import { OurPhotos } from './components/OurPhotos/OurPhotos';
import styled from 'styled-components';
import { MainInfo } from './components/MainInfo/MainInfo';
import { Feedback } from '../Game/features/Feedback';
import React, { useState } from 'react';
import { useIonViewDidEnter } from '@ionic/react';
import { Analytics } from '../../analytics';
import { ButtonSimple } from '../../components/ButtonSimple';

export const About = () => {
  const [showFeedback, setShowFeedback] = useState(false);

  useIonViewDidEnter(() => {
    Analytics.setCurrentScreen('About');
  }, []);

  return (
    <>
      <SWiteMe>
        <div>
          Если хочешь оставить сообщение, просто напиши мне в телеграм или здесь
          в приложении!
        </div>

        <SButtons>
          <ButtonStyled as={'a'} href={'https://t.me/polakoapp'}>
            @polakoapp
          </ButtonStyled>
          <SWriteHereButton onClick={() => setShowFeedback(true)}>
            Написать здесь
          </SWriteHereButton>
        </SButtons>
      </SWiteMe>

      <Feedback showModal={showFeedback} setShowModal={setShowFeedback} />
      <SPart>
        <OurPhotos />
      </SPart>
      <SPart>
        <MainInfo />
      </SPart>
    </>
  );
};

const SPart = styled.div`
  padding-top: 40px;

  &:first-child {
    padding-top: 0;
  }
`;

const SWiteMe = styled.div`
  background: white;
  padding: 16px;
  font-weight: 700;
  font-size: 12px;
  box-shadow: var(--polako-shadow);
  border: 2px solid var(--polako-black);
  display: flex;
  flex-direction: column;
  gap: 14px;
  line-height: 140%;
`;

const SButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonStyled = styled(ButtonSimple)`
  position: relative;
  background-color: var(--polako-green);
  padding: 8px;
  font-weight: 700;
  font-size: 10px;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SWriteHereButton = styled.a`
  width: 50%;
  padding: 8px;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
`;
