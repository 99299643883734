import { useRuntimeStore } from '../../initApp';
import { TPermission } from '../../types';

export const useIOSSetAccessToAllPermissions = () => {
  const setAccessToAllPermission = useRuntimeStore(
    (s) => s.setAccessToAllPermission
  );

  return {
    setBillingAccessToAllPermissions: (
      permission: TPermission | null,
      successCb?: () => void
    ) => {
      if (permission) {
        setAccessToAllPermission(permission);
        successCb?.();
        return true;
      }

      return false;
    },
  };
};
