import { STimeSettingLine } from './styles';
import { LabeledInput } from '../../../components/LabeledInput/LabeledInput';
import { Tumbler } from '../../../components/RadioButton/Tumbler';
import { TimeInput } from '../../../components/TimeInput';
import React from 'react';
import { useLocalNotifications } from '../../../components/NotificationsSetup/useLocalNotifications';
import { useIonViewWillEnter } from '@ionic/react';
import styled from 'styled-components';

export const NotificationsSetup = () => {
  const {
    time,
    setTime,
    setRepeatedNotificationsTime,
    initNotificationsOnSettingsPage,
    onNotificationsCheckboxChange,
    isNotificationsOn,
  } = useLocalNotifications();

  useIonViewWillEnter(() => {
    initNotificationsOnSettingsPage();
  }, []);

  return (
    <STimeSettingLine>
      <LabeledInput label="Ежедневное напоминание о желании учить сербский">
        <Tumbler
          checked={isNotificationsOn}
          onChange={onNotificationsCheckboxChange}
        />
      </LabeledInput>
      <STimeInput disabled={!isNotificationsOn}>
        <TimeInput
          time={time}
          setTime={setTime}
          isDisabled={!isNotificationsOn}
          confirmSelectedTimeHandler={setRepeatedNotificationsTime}
        />
      </STimeInput>
    </STimeSettingLine>
  );
};

const STimeInput = styled.div<{ disabled: boolean }>`
  display: flex;
  opacity: ${(props) => (props.disabled ? 0.15 : 1)};
  position: relative;
  top: -5px;
`;
