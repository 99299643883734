import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthSubscriber } from './HOCs/AuthSubscriber';
import App from './App';
import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { COLORS } from './constants';
import { rootVariables } from './theme/rootVariables';
import { useRuntimeStore } from './initApp';
import { TColor } from './types';
import { GlobalStyleComponent } from './theme/GlobalStyleComponent';

export function AppWrapper({
  preferences,
}: {
  preferences: { color: TColor };
}) {
  const resetErrorBoundary = async () => {
    await FirebaseAuthentication.removeAllListeners();
  };

  const setTheme = useRuntimeStore((s) => s.setTheme);
  const theme = useRuntimeStore((s) => s.theme);

  useEffect(() => {
    setTheme(preferences.color);
  }, [preferences.color]);

  return (
    <>
      <GlobalStyleComponent color={theme} />

      <ErrorBoundary fallbackRender={() => ''} onReset={resetErrorBoundary}>
        <AuthSubscriber>
          <App />
        </AuthSubscriber>
      </ErrorBoundary>
    </>
  );
}
