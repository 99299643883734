import {
  CustomerInfo,
  LOG_LEVEL,
  Purchases,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { TPermission, TPolakoSku } from '../types';
import { REVENUE_CAT_KEY } from '../constants';
import { Capacitor } from '@capacitor/core';

export class RevenueCatBilling {
  private originalSkus: Map<string, PurchasesPackage> = new Map();

  getRevenueCatKey() {
    const platform = Capacitor.getPlatform();

    if (platform !== 'ios' && platform !== 'android') {
      return undefined;
    }

    return REVENUE_CAT_KEY[platform];
  }

  async initializeApp() {
    try {
      const apiKey = this.getRevenueCatKey();

      if (!apiKey) {
        return;
      }

      // Короче я дошел до того что мне приходят доступы "Доступы тест" но не нормальные доступы, см логи 'offerings'

      await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }); // Enable to get debug logs

      await Purchases.configure({
        apiKey,
      });

      console.log('RevenueCat initialized');
    } catch (e) {
      console.error('Error initializing RevenueCat', e);
    }
  }

  async connectCustomSubscriber(subscriberId: string) {
    try {
      await Purchases.logIn({ appUserID: subscriberId });
      console.log(`Connected custom subscriber: ${subscriberId}`);
    } catch (e) {
      console.error('Error connecting subscriber', e);
    }
  }

  async restorePurchases(): Promise<TPermission | null> {
    const purchaserInfo = await Purchases.restorePurchases();
    return this.getTPermissionByRevenueCatPermissions(
      purchaserInfo.customerInfo
    );
  }

  async getPermissionToUseFullApp(): Promise<TPermission | null> {
    try {
      const customerInfo = await Purchases.getCustomerInfo();

      return this.getTPermissionByRevenueCatPermissions(
        customerInfo.customerInfo
      );
    } catch (e) {
      console.error('Error getting permissions', e);
      return null;
    }
  }

  async getSkus(): Promise<TPolakoSku[] | undefined> {
    try {
      const offerings = await Purchases.getOfferings();

      console.log('offerings', offerings);

      const offering = offerings.all['subscription_price_1'];

      if (!offering) return;

      const skus = offering.availablePackages.map((pkg) => {
        this.originalSkus.set(pkg.product.identifier, pkg);
        return this.mapRevenueCatSkuToPolakoSku(pkg);
      });

      return skus;
    } catch (e) {
      console.error('Error fetching SKUs', e);
    }
  }

  getOriginalSkuById(id: string): PurchasesPackage | undefined {
    return this.originalSkus.get(id);
  }

  async purchaseSku(sku: TPolakoSku): Promise<TPermission> {
    const originalSku = this.getOriginalSkuById(sku.id);
    if (!originalSku) {
      throw new Error(`Original SKU not found for id: ${sku.id}`);
    }

    try {
      const { customerInfo } = await Purchases.purchasePackage({
        aPackage: originalSku,
      });

      const permission =
        this.getTPermissionByRevenueCatPermissions(customerInfo);

      if (!permission) {
        throw new Error('Permission not found in transaction');
      }

      return permission;
    } catch (e) {
      console.error('Error purchasing SKU', e);
      throw e;
    }
  }

  private mapRevenueCatSkuToPolakoSku(pkg: PurchasesPackage): TPolakoSku {
    const { product } = pkg;

    return {
      id: product.identifier,
      productId: product.identifier,
      title: product.title,
      description: product.description,
      extraText: '',
      priceWithCurrency: product.priceString,
      period: product.subscriptionPeriod,
    };
  }

  private getTPermissionByRevenueCatPermissions(
    customerInfo: CustomerInfo
  ): TPermission | null {
    const entitlement = customerInfo.entitlements.all['access-to-all-lessons'];

    if (entitlement?.isActive && entitlement?.expirationDate) {
      return {
        expireDate: new Date(entitlement.expirationDate).getTime(),
      };
    }

    return null;
  }
}
