import { SubscriptionModalInner } from './SubscriptionModalInner';
import React from 'react';
import { useRuntimeStore } from '../../initApp';

export const SubscriptionModal = () => {
  const toggleSubscriptionPopup = useRuntimeStore(
    (s) => s.toggleSubscriptionPopup
  );
  const isSubscriptionPopupVisible = useRuntimeStore(
    (s) => s.isSubscriptionPopupVisible
  );

  return (
    <>
      {isSubscriptionPopupVisible && (
        <SubscriptionModalInner close={toggleSubscriptionPopup} />
      )}
    </>
  );
};
