import Star from '../../assets/images/Star.svg';
import { CatAngleWithBubble } from './components/CatAngleWithBubble';
import { CatFireworkWithBubble } from './components/CatCelebratesBubble';
import React from 'react';
import styled from 'styled-components';

export const PromoCats = () => {
  return (
    <SAbsoluteStuff>
      <SStar>
        <Star />
      </SStar>

      <CatAngleWithBubble
        side="right"
        text="30+"
        textSub="тем грамматики (будет больше)"
      />

      <CatAngleWithBubble
        side="left"
        text="Ново!"
        textSub="Регулярно обновления"
      />

      <CatFireworkWithBubble text="Скидка" textSub="потом чуток подорожает" />
    </SAbsoluteStuff>
  );
};

const SAbsoluteStuff = styled.div`
  position: relative;
  height: 510px;

  margin-top: -100px;
`;

const SStar = styled.div`
  position: absolute;
  top: 0;
  left: -70px;
  z-index: -1;
`;
