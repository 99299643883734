import './Game.css';
import { useGame } from './hooks/useGame';
import { TTopic } from './types';
import React, { useEffect, useState } from 'react';
import { Book } from './features/Book';

import { Feedback } from './features/Feedback';
import { Tip } from './features/Tip';

import styled from 'styled-components';
import { useShakeAnimation } from './hooks/useShake';
import { useIonViewDidEnter } from '@ionic/react';
import { useHideTabs } from '../../hooks/useHideTabs';
import { Analytics } from '../../analytics';
import { GameSignIn } from './features/GameSignIn';
import { useRuntimeStore } from '../../initApp';
import { useShowSignIn } from './hooks/useShowSignIn';
import { NotificationModal } from '../../components/NotificationsSetup/NotificationsSetup';
import CatProgress from './components/Progress/Progress';
import { roundScore } from '../../utils/roundScore';
import { PuzzleButtons } from './components/PuzzleButtons';
import { GameHeader } from './components/Header';
import { GameFooter } from './components/GameFooter';
import { ConditionAndAnswer } from './components/ConditionAndAnswer';

type TProps = {
  topic: TTopic;
};

export const Game = ({ topic }: TProps) => {
  const {
    isCorrect,
    userSelectedWordsArr,
    readyClickHandler,
    nextClickHandler,
    currentSentence,
    currentVideos,
    wordsToChooseFrom,
    wordClickHandler,
    alreadyPressedIndexes,
    showTipClickHandler,
    userSentenceStr,
    undoClickHandler,
    isReady,
    tipText,
    setTipText,
    lessonStat,
    getIfShouldOpenBook,
    isOnlyButtonMode,
    playSound,
  } = useGame(topic);

  const isNotificationsWindowOpened = useRuntimeStore(
    (s) => s.isNotificationsWindowOpened
  );
  const setIsNotificationsWindowOpened = useRuntimeStore(
    (s) => s.setIsNotificationsWindowOpened
  );

  useIonViewDidEnter(() => {
    Analytics.setCurrentScreen('Game');
  }, []);

  useHideTabs();

  const [showBook, setShowBook] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  const [shouldShake, triggerShake] = useShakeAnimation();

  useEffect(() => {
    getIfShouldOpenBook().then((isLessonOpenedFirstTime) => {
      if (isLessonOpenedFirstTime) {
        setShowBook(true);
      }
    });
  }, [topic.path]);

  const { user } = useRuntimeStore();

  useShowSignIn({
    isAnonymous: user?.isAnonymous ?? true,
    lessonStat,
    setShowSignIn,
  });

  if (!isReady) {
    return null;
  }

  return (
    <>
      {isNotificationsWindowOpened && (
        <NotificationModal
          close={() => setIsNotificationsWindowOpened(false)}
        />
      )}
      {/*<EncouragingCat*/}
      {/*  isShown={isUnblockCatShown}*/}
      {/*  text={unblockCatShownText}*/}
      {/*  side="right"*/}
      {/*/>*/}
      {showBook && (
        <Book
          close={() => {
            setShowBook(false);
            triggerShake();
          }}
          topic={topic}
          videos={currentVideos}
        />
      )}

      {showSignIn && (
        <GameSignIn
          onSuccess={() => {
            setShowSignIn(false);
          }}
          close={() => {
            setShowSignIn(false);
          }}
        />
      )}

      <Feedback showModal={showFeedback} setShowModal={setShowFeedback} />

      {Boolean(tipText) && (
        <Tip text={tipText} close={() => setTipText(null)} />
      )}

      <CatProgress score={roundScore(lessonStat?.score || 1)} />

      <GameHeader
        topic={topic}
        shouldShake={shouldShake}
        setShowBook={setShowBook}
        setShowFeedback={setShowFeedback}
      />

      <SContent>
        <div>
          <ConditionAndAnswer
            currentSentence={currentSentence}
            lessonStat={lessonStat}
            isOnlyButtonMode={isOnlyButtonMode}
            userSentenceStr={userSentenceStr}
          />
        </div>
        <div>
          <PuzzleButtons
            wordsToChooseFrom={wordsToChooseFrom}
            alreadyPressedIndexes={alreadyPressedIndexes}
            wordClickHandler={wordClickHandler}
          />

          <GameFooter
            isCorrect={isCorrect}
            userSelectedWordsArr={userSelectedWordsArr}
            readyClickHandler={readyClickHandler}
            nextClickHandler={nextClickHandler}
            undoClickHandler={undoClickHandler}
            showTipClickHandler={showTipClickHandler}
            playSound={playSound}
          />
        </div>
      </SContent>
    </>
  );
};

const SContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--ion-background-color);

  padding: var(--polako-padding);
  padding-bottom: var(--polako-padding-bottom);
`;
