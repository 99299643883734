import { LabeledInput } from '../../../components/LabeledInput/LabeledInput';
import { COLORS } from '../../../constants';
import { Checkbox } from '../../Game/components/Checkbox';
import { STimeSettingLine } from './styles';
import React from 'react';
import { useRuntimeStore } from '../../../initApp';
import styled from 'styled-components';

export const ColorSwitcher = () => {
  const setTheme = useRuntimeStore((s) => s.setTheme);
  const theme = useRuntimeStore((s) => s.theme);

  return (
    <STimeSettingLine>
      <LabeledInput label="Цвет фона">
        <SThemeCheckboxes>
          {Object.keys(COLORS).map((colorPresetName) => {
            return (
              <Checkbox
                checked={theme === colorPresetName}
                onChange={() => {
                  setTheme(colorPresetName);
                }}
                backgroundColor={
                  COLORS[colorPresetName]?.hex || COLORS.default.hex
                }
              ></Checkbox>
            );
          })}
        </SThemeCheckboxes>
      </LabeledInput>
    </STimeSettingLine>
  );
};

const SThemeCheckboxes = styled.div`
  display: flex;
  gap: 10px;
`;
