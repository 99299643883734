import { Typo } from '../Typo';
import { FlowerPoint } from '../FlowerPoint';
import { SubscriptionContent } from './components/SubscriptionContent';
import React from 'react';
import { useHideShow } from '../../hooks/useHideShow';
import styled from 'styled-components';
import Heart1 from '../../assets/images/heart1.svg';
import Heart2 from '../../assets/images/heart2.svg';

export const WhyPaid = () => {
  const [showWhyForMoney, _, isShownWhyForMoney] = useHideShow(false);

  return (
    <>
      <Heart1Styled />
      <Heart2Styled />
      <SubscriptionContent title="Почему платно?">
        <Typo.Text>
          Потому что я ежедневно работаю над приложением с начала 2023 года,
          потратил кучу сил и денег. Но мне нравится 😁❤️ И хочется продолжать.
          <br />
          <br />
        </Typo.Text>

        {isShownWhyForMoney && (
          <>
            <br />
            <Typo.Text>
              Изначально я хотел сделать бесплатное приложение, по-быстрому и
              "на коленке", но альтруистические идеалы разбились о реальность.
            </Typo.Text>
            <br />
            <FlowerPoint>
              <Typo.Text>
                надо платить эпплу и гуглу, чтобы просто находиться в сторе 💰🤯
              </Typo.Text>
            </FlowerPoint>
            <FlowerPoint>
              <Typo.Text>платить преподавателям за помощь 🙏</Typo.Text>
            </FlowerPoint>
            <FlowerPoint>
              <Typo.Text>
                платить дизайнеру за все кнопочки и тенюшечки
              </Typo.Text>
            </FlowerPoint>
            <FlowerPoint>
              <Typo.Text>
                красноглазить ночами после работы (я до этого никогда не делал
                приложений) 😰
              </Typo.Text>
            </FlowerPoint>
            <FlowerPoint>
              <Typo.Text>как-то мотивироваться развивать проект </Typo.Text>
            </FlowerPoint>
            <br />
            <Typo.Text>
              Спасибо всем кто поддержал и пишет добрые слова! Если бы этого не
              было, я бы уже давно сдулся ❤️
            </Typo.Text>
          </>
        )}
        {!isShownWhyForMoney && (
          <Typo.Text>
            <SPseudoLink onClick={showWhyForMoney}>Чуть подробнее</SPseudoLink>
          </Typo.Text>
        )}
      </SubscriptionContent>
    </>
  );
};

const SPseudoLink = styled.span`
  font-size: 10px;
  text-decoration: dashed;
  border-bottom: 1px dashed black;
  cursor: pointer;
`;

const Heart1Styled = styled(Heart1)`
  position: absolute;
  top: -50px;
  left: 50px;
`;

const Heart2Styled = styled(Heart2)`
  position: absolute;
  top: -24px;
  right: 80px;
`;
