import styled from 'styled-components';
import { FC, ReactNode } from 'react';

const LabeledRadioButtonWrapper = styled.label`
  display: flex;
`;

export const LabeledInput: FC<{ label: string; children: ReactNode }> = ({
  label,
  children,
}) => (
  <LabeledRadioButtonWrapper>
    {children}
    <SLabel>{label}</SLabel>
  </LabeledRadioButtonWrapper>
);

const SLabel = styled.span`
  margin-left: 20px;
  //margin-top: px;
  font-size: 14px;
  font-weight: 700;
`;
