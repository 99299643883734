import type React from 'react';
import styled from 'styled-components';

interface CheckboxContainerProps {
  backgroundColor?: string;
  checked: boolean;
}

const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  all: unset;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  line-height: 0;
`;

const StyledCheckbox = styled.div<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${(props) => props.backgroundColor};
  border: 1px solid #000;
  box-shadow: var(--polako-shadow);
`;

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
  width: 16px;
  height: 16px;
`;

interface CustomCheckboxProps {
  className?: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  backgroundColor?: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  className,
  checked,
  onChange,
  backgroundColor,
}) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} onChange={onChange} />
    <StyledCheckbox checked={checked} backgroundColor={backgroundColor}>
      {checked && (
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      )}
    </StyledCheckbox>
  </CheckboxContainer>
);

export const Checkbox = CustomCheckbox;
