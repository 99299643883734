import { useEffect } from 'react';
import { useRuntimeStore } from '../../initApp';
import { useIonViewDidEnter } from '@ionic/react';

export const useDaysInRawStat = () => {
  const { getConsecutiveDays, consecutiveDays } = useRuntimeStore();

  useEffect(() => {
    getConsecutiveDays();
  }, []);

  return consecutiveDays || 0;
};
