import { BackButton } from '../../../components/BackButton';
import React from 'react';
import { Header } from '../../../components/Header';
import styled, { css, keyframes } from 'styled-components';
import { IconButton } from '../../../components/IconButton';
import iconBook from './../../../assets/icons/book.svg?inline';
import iconDisike from './../../../assets/icons/dislike.svg?inline';
import { TTopic } from '../types';
import { getLessonTitle } from '../../../utils/getLessonTitle';
import { getLessonNumberByPath } from '../../../utils/getLessonNumberByPath';

type TProps = {
  topic: TTopic;
  shouldShake: boolean;
  setShowBook: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFeedback: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GameHeader = ({
  topic,
  shouldShake,
  setShowBook,
  setShowFeedback,
}: TProps) => {
  return (
    <Header>
      <BackButton
        text={getLessonTitle(getLessonNumberByPath(topic.path), topic.name)}
      ></BackButton>
      <SHeaderButtons>
        <IconButtonStyled
          size={'m'}
          $shouldShake={shouldShake}
          icon={iconBook}
          fontSize={16}
          onClick={() => {
            setShowBook(true);
          }}
        ></IconButtonStyled>

        <IconButtonStyled
          size={'m'}
          icon={iconDisike}
          onClick={() => setShowFeedback(true)}
        ></IconButtonStyled>
      </SHeaderButtons>
    </Header>
  );
};

const shake = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
`;

const SHeaderButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButtonStyled = styled(IconButton)<{ $shouldShake?: boolean }>`
  height: 32px;
  width: 32px;

  ${(props) =>
    props.$shouldShake &&
    css`
      animation: ${shake} 0.5s;
    `}
`;
