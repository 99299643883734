import React from 'react';
import styled from 'styled-components';
import { SubscriptionBlock } from './features/SubscriptionBlock';
import { SUBSCRIPTIONS_ENABLED } from '../../constants';
import { ColorSwitcher } from './features/ColorSwitcher';
import { NotificationsSetup } from './features/NotificationsSetup';
import { SignInButtons } from './features/SignInButtons';
import { BottomTechBlock } from './utils/BottomTechBlock';

export const Settings = () => {
  return (
    <SSettingsWrapper>
      <STopBlock>
        <div>
          {SUBSCRIPTIONS_ENABLED && (
            <SSubscriptionsBlock>
              <SubscriptionBlock />
            </SSubscriptionsBlock>
          )}

          <SSettingsLines>
            <NotificationsSetup />
            <ColorSwitcher />
          </SSettingsLines>
        </div>

        <SignInButtons />
      </STopBlock>

      <BottomTechBlock />
    </SSettingsWrapper>
  );
};
const SSubscriptionsBlock = styled.div`
  margin-bottom: 30px;
`;

const SSettingsLines = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const STopBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: 30px;
`;

const SSettingsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
