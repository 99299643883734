import { IonSpinner } from '@ionic/react';
import { BuyRadio } from '../BuyRadio/BuyRadio';
import { Capacitor } from '@capacitor/core';
import { Button } from '../Button';
import React from 'react';
import styled from 'styled-components';
import { SubscriptionContent } from './components/SubscriptionContent';
import { useSubscribeModal } from './hooks/useSubscribeModal';
import { TPolakoSku } from '../../types';

const PERIODS = {
  P1M: '1 месяц',
  P6M: '6 месяцев',
};

type TPeriods = keyof typeof PERIODS;

export const PricesAndBuyButton = () => {
  const {
    buy,
    successResult,
    allSkus,
    isLoading,
    selectedSku,
    setSelectedSku,
    storeError,
  } = useSubscribeModal({ close });

  const getText = (sku: TPolakoSku | null) => {
    if (!sku) return '';

    const { period } = sku;

    return `${PERIODS[period as TPeriods]}`;
  };

  const getTextLine2 = (sku: TPolakoSku | null) => {
    if (!sku) return '';

    return `${sku.priceWithCurrency}`;
  };

  return (
    <>
      {successResult && <div>Спасибо!</div>}
      <SubscriptionContentStyled title="Купить подписку на доступ ко всем урокам">
        {isLoading && (
          <SLoader>
            <IonSpinner />
          </SLoader>
        )}

        <br />

        {!isLoading && (
          <SButtons>
            {(allSkus || []).map((sku, key) => (
              <BuyRadio
                text={getText(sku)}
                textLine2={getTextLine2(sku)}
                extraText={sku.description}
                extraText2={sku.extraText}
                key={key}
                selected={selectedSku?.productId === sku.productId}
                onClick={() => {
                  setSelectedSku(sku);
                }}
              />
            ))}
          </SButtons>
        )}

        {storeError && (
          <p>
            Кажется, в вашей стране купить через магазин не получится. Но есть
            другой способ. <br />
            <br />
            <SLink href="https://polako.app/pay">Купить по-другому</SLink>
          </p>
        )}

        <br />

        {!storeError && (
          <div>
            <SLink href="https://polako.app/pay">
              Хочу оплатить, но не могу через{' '}
              {Capacitor.getPlatform() === 'ios' ? 'Apple' : 'Google Play'}, что
              делать?{' '}
            </SLink>
            <br />
            <br />
          </div>
        )}

        <SSmallLinks>
          <SLinkSmall href="https://polako.app/terms">
            Terms & Conditions
          </SLinkSmall>

          <SLinkSmall href="https://polako.app/privacy-policy">
            Privacy Policy
          </SLinkSmall>
        </SSmallLinks>
      </SubscriptionContentStyled>

      <br />
      <br />

      <SBuyButton>
        <Button
          text={isLoading ? '...' : 'Купить ' + getText(selectedSku)}
          onClick={buy}
          wide={true}
          square={false}
          disabled={isLoading || !selectedSku}
          size={'l'}
          fontSize={12}
          color={'green'}
        ></Button>
      </SBuyButton>
    </>
  );
};

const SLink = styled.a`
  font-size: 14px;
  padding-block: 10px;
`;

const SLinkSmall = styled.a`
  font-size: 7px;
  padding-block: 10px;
`;

const SSmallLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-end;
`;

const SubscriptionContentStyled = styled(SubscriptionContent)`
  padding-top: 30px;
`;

const SBuyButton = styled.div`
  position: sticky;
  height: 80px;
  width: 100%;
  background-color: var(--polako-purple);
  z-index: 1000;
  border-top: 2px solid var(--polako-black);
  bottom: -1px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const SButtons = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const SLoader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ButtonCopy = styled(Button)`
  &::part(native) {
    padding: 3px;
  }
`;
