import { TTask } from '../types';
import { trimAndRemovePunctuation } from './trimAndRemovePunctuation';

export const mixRealSentenceWordsWithFakeOnes = (data: TTask): string[] => {
  if (!data) {
    return [];
  }
  const foreign = trimAndRemovePunctuation(data.foreign).filter(
    (i) => i && i !== '_'
  );

  const random = data?.random || [];

  const allTogetgerShuffled = shuffle([...foreign, ...random]);

  return allTogetgerShuffled;
};

type TPlaceholderArray = string[];

export const getFilledString = (
  placeholders: TPlaceholderArray,
  template: string
): string => {
  let result: string = template;
  for (const placeholder of placeholders) {
    result = result.replace('_', placeholder);
  }
  return result;
};

function shuffle<T>(array: T[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
