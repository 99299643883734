import { createGlobalStyle } from 'styled-components';
import { TColor } from '../types';
import { rootVariables } from './rootVariables';
import { COLORS } from '../constants';

export const GlobalStyleComponent = createGlobalStyle<{ color: TColor }>`
    ${rootVariables}

    body {
        --ion-background-color: ${({ color }) =>
          COLORS[color]?.hex || COLORS.default.hex};
    }

    @media (prefers-color-scheme: dark) {
        body {
            --ion-background-color-rgb: ${({ color }) =>
              COLORS[color]?.rgba || COLORS.default.rgba};
        }
    }
`;
