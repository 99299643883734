import React, { FC, IframeHTMLAttributes } from 'react';
import styled from 'styled-components';

type TVideoProps = {
  src: string;
  name: string;
};
export const Video: FC<TVideoProps> = ({ src, name }) => {
  const videoType = src.includes('youtube.com') ? 'youtube' : 'vk';

  const videoIframeProps: IframeHTMLAttributes<HTMLIFrameElement> = {
    width: '90%',
    height: '150px',
    src,
    title: name,
    frameBorder: '0',
    allowFullScreen: true,
  };

  if (videoType === 'vk') {
    videoIframeProps.allow =
      'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
  }

  if (videoType === 'vk') {
    videoIframeProps.allow =
      'autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;';
  }

  return <SIframe {...videoIframeProps} />;
};

const SIframe = styled.iframe`
  box-shadow: var(--polako-shadow-thick);
`;
