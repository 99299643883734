import styled from 'styled-components';
import { Button } from '../../../components/Button';

import iconWrong from '../../../assets/icons/close.svg?inline';
import iconCorrect from '../../../assets/icons/correct.svg?inline';

import { TStorageLesson } from '../types';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { roundScore } from '../../../utils/roundScore';
import { useShowCatForMsNew } from '../components/EncouragingCat2/useShowCatForMsNew';

export const Scores: FC<{ lessonStat: TStorageLesson | null }> = ({
  lessonStat,
}) => {
  const { showCat } = useShowCatForMsNew();

  if (!lessonStat) return null;

  return (
    <SWrapper>
      {/*<Score*/}
      {/*  icon={*/}
      {/*    <Button*/}
      {/*      icon={iconStar}*/}
      {/*      size={'s'}*/}
      {/*      color="purple"*/}
      {/*      fontSize={20}*/}
      {/*      onClick={() => {*/}
      {/*        showCat({ text: 'Это оценка за урок' });*/}
      {/*      }}*/}
      {/*    ></Button>*/}
      {/*  }*/}
      {/*>*/}
      {/*  {roundScore(lessonStat.score)}*/}
      {/*</Score>*/}

      <Score
        icon={
          <Button
            icon={iconWrong}
            size={'s'}
            color="red"
            fontSize={20}
            onClick={() => {
              showCat({ text: 'Сколько раз ошибся' });
            }}
          ></Button>
        }
      >
        {roundScore(lessonStat.wrong)}
      </Score>

      <Score
        icon={
          <Button
            icon={iconCorrect}
            size={'s'}
            color="green"
            fontSize={20}
            onClick={() => {
              showCat({ text: 'Сколько раз ответил верно' });
            }}
          ></Button>
        }
      >
        {roundScore(lessonStat.correct)}
      </Score>
    </SWrapper>
  );
};

type TProps = {
  icon: ReactNode;
};

const Score: FC<PropsWithChildren<TProps>> = ({ icon, children }) => {
  return (
    <SScore>
      {icon}
      <SScoreNumber>{children}</SScoreNumber>
    </SScore>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SScore = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;

  color: var(--polako-black);
  font-weight: 700;
  text-align: center;
`;

const SScoreNumber = styled.div`
  font-size: 10px;
  padding-top: 5px;
`;
