import { Capacitor } from '@capacitor/core';
import { SignInButton } from '../../Signup/SignInButton';
import { logoApple, logoGoogle } from 'ionicons/icons';
import { Button } from '../../../components/Button';
import { SignedInButtons } from './SignedInButtons';
import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../hooks/useAuth';
import { useRuntimeStore } from '../../../initApp';

export const SignInButtons = () => {
  const { user } = useRuntimeStore();
  const { linkWithSocialAccount, signIn, removeAnonymousAccount } = useAuth();

  const [haveAccount, setHaveAccount] = React.useState(false);

  const logInOrSignUp = (strategy: 'apple' | 'google') => {
    if (haveAccount) {
      signIn(strategy, true);
    } else {
      linkWithSocialAccount(strategy);
    }
  };

  return (
    <SSignInButtons>
      {user?.isAnonymous ? (
        <>
          <STopBottomFlex>
            <SFlex>
              {Capacitor.getPlatform() === 'ios' && (
                <SignInButton
                  iconSrc={logoApple}
                  onClick={() => {
                    logInOrSignUp('apple');
                  }}
                  text={
                    haveAccount
                      ? 'Войти через эппл'
                      : 'Зарегистрироваться через эппл'
                  }
                />
              )}
              <SignInButton
                iconSrc={logoGoogle}
                onClick={() => logInOrSignUp('google')}
                text={
                  haveAccount
                    ? 'Войти через гугл'
                    : 'Зарегистрироваться через гугл'
                }
              />
              <SLink onClick={() => setHaveAccount(!haveAccount)}>
                {!haveAccount ? 'У меня есть акаунт' : 'У меня нет акаунта'}
              </SLink>
            </SFlex>
            <div>
              <Button
                square={false}
                text={'Выйти из демо режима'}
                onClick={removeAnonymousAccount}
                size={'m'}
              ></Button>
            </div>
          </STopBottomFlex>
        </>
      ) : (
        <SignedInButtons />
      )}
    </SSignInButtons>
  );
};

const SFlex = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding-bottom: 10px;
`;

const STopBottomFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSignInButtons = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SLink = styled.div`
  text-decoration: underline;
  color: white;
  font-size: 14px;
  cursor: pointer;
  font-weight: 900;
`;
