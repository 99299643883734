import { create } from 'zustand';
import {
  TGetLesson,
  TStorageLesson,
  TStorageLessons,
  TTopic,
  TTopicPath,
} from './pages/Game/types';
import {
  DEFAULT_LESSON_FIRST,
  LESSON_CONSIDERED_PASSED,
  MINIMUM_SCORE,
} from './constants';
import type { User } from '@capacitor-firebase/authentication/dist/esm/definitions';
import {
  TColor,
  TManualSubscription,
  TNotificationTime,
  TPermission,
  TSettings,
  TStat,
  TUnblockLessonResult,
  TUserData,
} from './types';
import { getTodayUtcDate, getUtcDateString } from './utils/getUtcDateString';
import { FirebaseFirestore } from '@capacitor-firebase/firestore';
import {
  decrementScoreBasic,
  incrementBasic,
  incrementScoreBasic,
} from './pages/Game/hooks/store/utils';
import { Analytics } from './analytics';
import { getLessonNumberByPath } from './utils/getLessonNumberByPath';
import { getNextLessonPath } from './utils/getNextLessonPath';
import { Preferences } from '@capacitor/preferences';

export type TZustandStore = {
  allLessonsOpened: boolean;
  getIfAllLessonsOpened: () => Promise<void>;
  getStartedLessons: () => Promise<TStorageLessons>;
  unblockNextLesson: (
    currentLessonPath: TTopicPath
  ) => Promise<TUnblockLessonResult>;
  unblockLesson: (
    currentLessonPath: TTopicPath
  ) => Promise<TUnblockLessonResult>;
  getNumericVal: (field: string) => Promise<number>;
  getPassedTopicsQty: () => Promise<number>;
  getCorrectlySolvedCount: () => Promise<number>;
  getSpentTime: () => Promise<number>;
  getStatPageData(): Promise<TStat>;
  showTabs: boolean;
  setShowTabs: (showTabs: boolean) => void;
  consecutiveDays: number | null | undefined;
  getConsecutiveDays: () => Promise<void>;
  saveVisitDate: () => void;
  user: User | null;
  setUser: (user: User | null) => void;
  getUser: () => User | null;
  getPassedItems: (topic: TTopic) => Promise<string[]>;
  setPassedItems: (newPassedIndex: string | undefined, topic: TTopic) => void;
  incrementCorrect: (topic: TTopic) => void;
  incrementWrong: (topic: TTopic) => void;
  incrementGiveUp: (topic: TTopic) => void;
  incrementScore: (topic: TTopic) => void;
  decrementScore: (topic: TTopic) => void;
  getScoreByTopic: (topic: TTopic) => Promise<number>;
  incrementTimeSpentLearning: (
    topic: TTopic,
    timeDiffInSeconds: number
  ) => Promise<void>;
  getUserData: () => Promise<TUserData | null>;
  getVisitDates: () => Promise<TUserData['visitDates']>;
  getLesson: TGetLesson;
  updateLesson: (
    lessonId: string,
    updateData: Partial<TStorageLesson>
  ) => Promise<void>;
  createLesson: (
    lessonPath: TTopicPath,
    lesson?: TStorageLesson
  ) => Promise<void>;
  saveLocalPushNotificationsTime: (time: TNotificationTime) => Promise<void>;
  getLocalPushNotificationsTime: () => Promise<TNotificationTime>;
  currentSessionLessonsPassed: number;
  incCurrentSessionLessonsPassed: () => void;
  getIsNotificationsSetAlready: () => Promise<boolean>;
  isNotificationsWindowOpened: boolean;
  setIsNotificationsWindowOpened: (isOpened: boolean) => void;
  setAccessToAllPermission: (permission: TPermission) => void;
  accessToAllPermission: TPermission | null;
  getIsSubscriptionValid: () => boolean;
  isSubscriptionPopupVisible: boolean;
  toggleSubscriptionPopup: (isVisible?: boolean) => void;
  getManualSubscriptions: () => Promise<TManualSubscription[] | undefined>;
  settings: TSettings;
  getSettings: () => Promise<void>;
  setTheme: (theme: TColor) => void;
  theme: TColor;
};

export const createStore = () => {
  return create<TZustandStore>((set, get) => ({
    theme: 'pink',
    setTheme: async (theme) => {
      await Preferences.set({ key: 'color', value: theme });
      set({ theme });
    },
    settings: {},
    getSettings: async () => {
      const { snapshots: settingsSnaps } =
        await FirebaseFirestore.getCollection({
          reference: `settings`,
        });

      const settings = settingsSnaps.reduce((acc, snap) => {
        // @ts-expect-error - we know that snap.id is a key of TSettings
        acc[`${snap.id as keyof TSettings}`] = snap.data;
        return acc;
      }, {} as TSettings);

      set({ settings });
    },
    user: null,
    setUser: (user) => {
      set({ user });
    },
    getUser: () => {
      return get().user;
    },
    showTabs: true,
    setShowTabs: (showTabs: boolean) => set({ showTabs }),
    createLesson: async (
      lessonPath: TTopicPath,
      data = DEFAULT_LESSON_FIRST
    ) => {
      const user = get().user;
      if (!user) {
        return;
      }

      await FirebaseFirestore.setDocument({
        reference: `users/${user.uid}/lessons/${lessonPath}`,
        data,
        merge: true,
      });
    },
    getStartedLessons: async () => {
      const userId = get().user?.uid;
      const { snapshots: lessonsSnapshots } =
        await FirebaseFirestore.getCollection({
          reference: `users/${userId}/lessons`,
        });

      const lessons: TStorageLessons = {};

      lessonsSnapshots.forEach((doc) => {
        lessons[`${doc.id}`] = doc.data as TStorageLesson;
      });

      return lessons;
    },
    getLesson: async (topicPath: TTopicPath) => {
      const userId = get().user?.uid;

      const { snapshot: lessonSnapshot } = await FirebaseFirestore.getDocument({
        reference: `users/${userId}/lessons/${topicPath}`,
      });

      if (lessonSnapshot.data) {
        return lessonSnapshot.data as TStorageLesson;
      }

      return null;
    },
    getManualSubscriptions: async () => {
      const userId = get().user?.uid;
      const { snapshots } = await FirebaseFirestore.getCollection({
        reference: `users/${userId}/manualSubscriptions`,
      });

      if (snapshots.length === 0) {
        return;
      }

      return snapshots.map((snapshot) => snapshot.data as TManualSubscription);
    },
    getUserData: async () => {
      const user = get().user;

      if (user) {
        const { snapshot: docSnap } = await FirebaseFirestore.getDocument({
          reference: `users/${user.uid}`,
        });

        // https://github.com/capawesome-team/capacitor-firebase/tree/main/packages/firestore
        if (docSnap.data) {
          return docSnap.data as TUserData;
        }
      }

      console.log('No such a user!');
      return null;
    },
    saveVisitDate: async () => {
      const currentDate = getTodayUtcDate();
      const dates = await get().getVisitDates();

      if (!dates.includes(currentDate)) {
        dates.push(currentDate);
      }

      const user = get().user;

      if (user) {
        try {
          await FirebaseFirestore.updateDocument({
            reference: `users/${user.uid}`,
            data: {
              visitDates: dates,
            },
          });

          console.log('Новая дата посещения добавлена');
        } catch (error) {
          console.error('Ошибка при добавлении даты посещения: ', error);
        }
      }
    },

    saveLocalPushNotificationsTime: async (time) => {
      const user = get().user;

      if (user) {
        try {
          await FirebaseFirestore.updateDocument({
            reference: `users/${user.uid}`,
            data: {
              localPushNotifications: time,
            },
          });

          console.log('Новое время уведомлений записано', time);
        } catch (error) {
          console.error(
            'Ошибка при установке времени локальных уведомлений: ',
            error
          );
        }
      }
    },

    updateLesson: async (lessonId, data) => {
      const userId = get().user?.uid;

      try {
        await FirebaseFirestore.updateDocument({
          reference: `users/${userId}/lessons/${lessonId}`,
          data,
        });

        console.log(`Урок '${lessonId}' обновлен данными`, data);
      } catch (error) {
        console.error(`Ошибка при обновлении урока: ${error}`);
        throw error;
      }
    },
    getVisitDates: async () => {
      const userData = await get().getUserData();
      return userData?.visitDates || [];
    },
    allLessonsOpened: false,
    getIfAllLessonsOpened: async () => {
      let allLessonsOpened = false;
      if (import.meta.env.VITE_UNBLOCK_ALL_LESSONS === 'true') {
        set({ allLessonsOpened });
        return;
      }

      const userData = await get().getUserData();
      allLessonsOpened = userData?.allLessonsOpened || false;

      set({ allLessonsOpened });
    },
    getLocalPushNotificationsTime: async () => {
      const userData = await get().getUserData();
      return userData?.localPushNotifications;
    },
    unblockNextLesson: async (currentLessonPath: TTopicPath) => {
      const nextLessonPath = getNextLessonPath(currentLessonPath);
      return get().unblockLesson(nextLessonPath);
    },
    unblockLesson: async (lessonPath: TTopicPath) => {
      const lessonNumber = getLessonNumberByPath(lessonPath);
      let lesson = await get().getLesson(lessonPath);
      let isLessonUnblocked = false;

      if (!lesson) {
        try {
          await get().createLesson(lessonPath);
          lesson = await get().getLesson(lessonPath);
        } catch (error) {
          console.log('Ошибка при создании урока', error);
        }

        Analytics.trackLessonUnblocked(lessonNumber);

        isLessonUnblocked = true;
      } else if (lesson.blocked) {
        await get().updateLesson(lessonPath, {
          blocked: false,
        });
        lesson = await get().getLesson(lessonPath);
        isLessonUnblocked = true;
      }

      return {
        isLessonUnblocked,
        lesson,
      };
    },
    getNumericVal: async (field: string) => {
      const allPassed = await get().getStartedLessons();
      let sum = 0;

      Object.keys(allPassed).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sum += allPassed[key][field] || 0;
      });

      return sum;
    },
    getSpentTime: async () => {
      return get().getNumericVal('time');
    },
    getCorrectlySolvedCount: async () => {
      return get().getNumericVal('correct');
    },
    getPassedTopicsQty: async () => {
      const allPassed = await get().getStartedLessons();
      const allScores: number[] = [];

      Object.keys(allPassed).forEach((key) => {
        if (allPassed[key].score) {
          allScores.push(allPassed[key].score);
        }
      });

      const filtered = allScores.filter((item) => {
        return item >= LESSON_CONSIDERED_PASSED;
      });

      return filtered.length;
    },

    getStatPageData: async (): Promise<TStat> => {
      const solved = await get().getCorrectlySolvedCount();
      const time = await get().getSpentTime();
      const passedTopicsQty = await get().getPassedTopicsQty();

      return {
        solved,
        time,
        passedTopicsQty,
      };
    },

    consecutiveDays: undefined,

    getConsecutiveDays: async () => {
      const dates = await get().getVisitDates();

      const currentDate = new Date();
      let count = 0;

      // Отформатируем текущую дату в строку YYYY-MM-DD
      let currentDateString = getUtcDateString(currentDate);

      while (dates.includes(currentDateString)) {
        count++;
        currentDate.setDate(currentDate.getDate() - 1);
        currentDateString = getUtcDateString(currentDate);
      }

      set({ consecutiveDays: count });
    },

    // getTotalDays: async () => {
    //   const dates = await get().store.get('visitDates');
    //   return dates ? dates.length : 0;
    // },

    getPassedItems: async (topic: TTopic): Promise<string[]> => {
      const lesson = await get().getLesson(topic.path);
      return lesson?.solvedIds ? lesson.solvedIds : [];
    },

    setPassedItems: async (
      newPassedIndex: string | undefined,
      topic: TTopic
    ) => {
      if (!newPassedIndex) {
        throw new Error('newPassedIndex is not passed');
      }

      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      await get().updateLesson(topic.path, {
        solvedIds: [...lesson.solvedIds, newPassedIndex],
      });
    },

    incrementCorrect: async (topic: TTopic) => {
      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      await get().updateLesson(topic.path, {
        correct: incrementBasic(lesson.correct),
      });
    },

    incrementWrong: async (topic: TTopic) => {
      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      await get().updateLesson(topic.path, {
        wrong: incrementBasic(lesson.wrong),
      });
    },

    incrementGiveUp: async (topic: TTopic) => {
      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      await get().updateLesson(topic.path, {
        giveUps: incrementBasic(lesson.giveUps),
      });
    },
    incrementScore: async (topic: TTopic) => {
      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      await get().updateLesson(topic.path, {
        score: incrementScoreBasic(lesson.score),
      });
    },

    decrementScore: async (topic: TTopic) => {
      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      await get().updateLesson(topic.path, {
        score: decrementScoreBasic(lesson.score),
      });
    },

    getScoreByTopic: async (topic: TTopic) => {
      const lesson = await get().getLesson(topic.path);
      return lesson?.score || MINIMUM_SCORE;
    },

    incrementTimeSpentLearning: async (
      topic: TTopic,
      timeDiffInSeconds: number
    ) => {
      const lesson = await get().getLesson(topic.path);
      if (!lesson) {
        return;
      }

      console.log(
        `Время в уроке ${topic.path} увеличено на `,
        timeDiffInSeconds
      );

      await get().updateLesson(topic.path, {
        time: incrementBasic(lesson.time || 0, timeDiffInSeconds),
      });
    },
    incCurrentSessionLessonsPassed: async () => {
      const cur = get().currentSessionLessonsPassed;

      set({ currentSessionLessonsPassed: cur + 1 });
    },
    currentSessionLessonsPassed: 0,
    getIsNotificationsSetAlready: async () => {
      const notificationsTime = await get().getLocalPushNotificationsTime();
      return notificationsTime !== undefined && notificationsTime !== null;
    },
    isNotificationsWindowOpened: false,
    setIsNotificationsWindowOpened: (isOpened: boolean) => {
      set({ isNotificationsWindowOpened: isOpened });
    },
    accessToAllPermission: null,
    setAccessToAllPermission: (accessToAllPermission) => {
      set({ accessToAllPermission });
    },
    getIsSubscriptionValid: () => {
      return Boolean(get().accessToAllPermission);
    },
    isSubscriptionPopupVisible: false,
    toggleSubscriptionPopup: (isVisible) => {
      if (typeof isVisible === 'boolean') {
        set({ isSubscriptionPopupVisible: isVisible });
        return;
      }

      set({ isSubscriptionPopupVisible: !get().isSubscriptionPopupVisible });
    },
  }));
};
