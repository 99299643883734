import { Typo } from '../../../components/Typo';
import React, { useEffect } from 'react';
import { useAccessToAllPermissions } from '../../../components/SubscribeModal/hooks/useAccessToAllPermissions';
import styled from 'styled-components';
import { SubscriptionContent } from '../../../components/SubscribeModal/components/SubscriptionContent';
import { Button } from '../../../components/Button';
import { useRuntimeStore } from '../../../initApp';
import { getSubscriptionExpiringDateStr } from '../utils/getSubscriptionExpiringDateStr';
import { ButtonCopy } from '../../../components/ButtonCopy';
import { useRestorePurchases } from '../useRestorePurchases';
import { SubscriptionModal } from '../../../components/SubscribeModal/SubscriptionModal';

export const SubscriptionBlock = () => {
  const { isPermissionsLoading, accessToAllPermission } =
    useAccessToAllPermissions();

  const getIsSubscriptionValid = useRuntimeStore(
    (s) => s.getIsSubscriptionValid
  );
  const toggleSubscriptionPopup = useRuntimeStore(
    (s) => s.toggleSubscriptionPopup
  );
  const isSubscriptionPopupVisible = useRuntimeStore(
    (s) => s.isSubscriptionPopupVisible
  );
  const getSettings = useRuntimeStore((s) => s.getSettings);
  const settings = useRuntimeStore((s) => s.settings);
  const { restorePurchases } = useRestorePurchases();

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <>
      {isPermissionsLoading && <Typo.Text>Загрузка...</Typo.Text>}
      {getIsSubscriptionValid() && (
        <SubscriptionContentStyled title="Подписка">
          <Typo.Text>
            Ваша подписка активна до{' '}
            {getSubscriptionExpiringDateStr(accessToAllPermission?.expireDate)}
          </Typo.Text>
          {settings.texts?.openAllLessonsManually && (
            <>
              <br />
              <Typo.Text>{settings.texts?.openAllLessonsManually}</Typo.Text>
            </>
          )}
        </SubscriptionContentStyled>
      )}
      {!isPermissionsLoading && !getIsSubscriptionValid() && (
        <SubscriptionContentStyled title="Подписка не активна">
          <Typo.Text>
            Оформите подписку, чтобы получить доступ ко всем урокам
          </Typo.Text>

          <br />
          <Button
            square={false}
            text={'Оформить подписку'}
            onClick={() => toggleSubscriptionPopup()}
            size={'m'}
            wide={true}
            fontSize={12}
            color={'yellow'}
          ></Button>

          <br />
          <br />
          <SFlex>
            <ButtonCopy
              size={'s'}
              text={'Восстановить покупки'}
              onClick={restorePurchases}
              square={false}
              wide={true}
            />

            <Typo.Text>* Если что-то пошло не так</Typo.Text>
          </SFlex>
        </SubscriptionContentStyled>
      )}
      {isSubscriptionPopupVisible && <SubscriptionModal />}
    </>
  );
};

const SubscriptionContentStyled = styled(SubscriptionContent)`
  background-color: var(--polako-green);
  width: 100%;
`;

const SFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 4px;
`;
