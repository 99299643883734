import styled from 'styled-components';
import React from 'react';
import { ButtonSimple } from './ButtonSimple';
import { useRuntimeStore } from '../initApp';

type SubscribeButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: () => void;
};

export const SubscribeButton: React.FC<SubscribeButtonProps> = (props) => {
  const toggleSubscriptionPopup = useRuntimeStore(
    (s) => s.toggleSubscriptionPopup
  );

  return (
    <>
      <ButtonSimpleStyled
        {...props}
        onClick={() => {
          props.onClick && props.onClick();
          toggleSubscriptionPopup(true);
        }}
      >
        <SThankYouWrapper>
          <SThankYouIcon>💰</SThankYouIcon>
          <SThankYouText>Подписочка ❤️</SThankYouText>
        </SThankYouWrapper>
      </ButtonSimpleStyled>
    </>
  );
};

const ButtonSimpleStyled = styled(ButtonSimple)`
  background-color: var(--polako-green);
  width: fit-content;
`;

const SThankYouWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SThankYouText = styled.div`
  font-size: 10px;
  width: 90px;
`;

const SThankYouIcon = styled.div`
  font-size: 20px;
`;
