import { IonBackdrop } from '@ionic/react';
import styled, { css } from 'styled-components';
import { CloseButton } from '../CloseButton';
import { FC, HTMLProps, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

type TPosition = 'middle' | 'bottom' | undefined;
type TProps = HTMLProps<HTMLDivElement> & {
  close: () => void;
  withBackdrop?: boolean;
  className?: string;
  position?: TPosition;
};

export const Modal: FC<PropsWithChildren<TProps>> = ({
  children,
  close,
  withBackdrop = true,
  position = 'middle',
  ...divProps
}) => {
  const backdropVisible = useBackDropVisible();

  return createPortal(
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-ignore*/}
      <SModal {...divProps} $position={position}>
        {close && (
          <SCloseWrapper>
            <CloseButton onClick={close} size={'m'} />
          </SCloseWrapper>
        )}
        <SContent>{children}</SContent>
        <span className="bottom-dots"></span>
      </SModal>

      {withBackdrop && backdropVisible && (
        <IonBackdrop visible onIonBackdropTap={close}></IonBackdrop>
      )}
    </>,
    document.body
  );
};

const useBackDropVisible = () => {
  // const [backdropVisible, setBackdropVisible] = useState(false);
  //
  // useEffect(() => {
  //   const timer = setTimeout(function () {
  //     setBackdropVisible(true);
  //   }, 200);
  //
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  return true;
  // return backdropVisible;
};

const SContent = styled.div`
  overflow-y: auto;
  width: 100%;
`;

export const SWrapperWithDots = styled.div`
  --tip-shift: -10px;

  position: relative;
  padding: 16px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  border: 6px solid black;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.408px;

  &::before,
  &::after,
  & > span::before,
  & > span::after {
    content: '';
    position: absolute;
    width: 12px; /* Ширина квадратика */
    height: 12px; /* Высота квадратика */
    border: 2px solid black;
    background-color: white; /* Цвет квадратика */
    box-shadow: var(--polako-shadow-minimal);
    z-index: 1001;
  }

  &::before {
    /* Левый верхний угол */
    top: var(--tip-shift);
    left: var(--tip-shift);
  }

  &::after {
    /* Правый верхний угол */
    top: var(--tip-shift);
    right: var(--tip-shift);
  }

  & > span::before {
    /* Левый нижний угол */
    content: '';
    bottom: var(--tip-shift);
    left: var(--tip-shift);
  }

  & > span::after {
    /* Правый нижний угол */
    content: '';
    bottom: var(--tip-shift);
    right: var(--tip-shift);
  }
`;

export const SModal = styled(SWrapperWithDots)<{ $position?: TPosition }>`
  position: fixed;
  left: 5%;
  background: var(--ion-background-color);
  max-height: calc(90vh - 90px);
  width: 90%;
  align-items: center;

  ${(props) => {
    if (props.$position === 'middle') {
      return `
        top: calc(50%);
        transform: translateY(-50%);
      `;
    }
  }};

  ${(props) => {
    if (props.$position === 'bottom') {
      return css`
        bottom: var(--polako-padding-bottom);
        left: 5%;
      `;
    }
  }};
`;

const SCloseWrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 2000;
`;
