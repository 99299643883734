import { useRuntimeStore } from '../initApp';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { setInitialData } from '../pages/Signup/firebase';
import { Analytics } from '../analytics';
// @ts-ignore
import LogoCatFace from '../assets/images/cat-face.svg?inline';
import styled from 'styled-components';
import type { User } from '@capacitor-firebase/authentication/dist/esm/definitions';
import { useSetUserAndConnectBilling } from '../hooks/billing/useSetUserAndConnectBilling';

export const AuthSubscriber: FC<{ children: ReactNode }> = ({ children }) => {
  const { setUser } = useRuntimeStore();
  const [isLoading, setLoading] = useState(true);
  const { setUserAndConnectBilling } = useSetUserAndConnectBilling();

  const setUserAndAnalytics = async (user: User | null) => {
    await setUserAndConnectBilling(user);
    await Analytics.setUserId(user?.uid);
  };

  useEffect(() => {
    const handleBackFromBackground = async () => {
      // When using live reload (OR , the auth state change
      // listener is not added again after a page reload.
      const currentUserResult = await FirebaseAuthentication.getCurrentUser();

      if (currentUserResult?.user) {
        await setUserAndAnalytics(currentUserResult.user);
        await setInitialData(currentUserResult.user.uid);
        setLoading(false);
      }
    };

    handleBackFromBackground();
  }, []);

  useEffect(() => {
    const initAuthStuff = async () => {
      await FirebaseAuthentication.removeAllListeners();

      await FirebaseAuthentication.addListener(
        'authStateChange',
        async (result) => {
          try {
            if (result.user) {
              await setInitialData(result.user.uid);
              Analytics.setSignIn();
            }

            setLoading(false);
            setUserAndAnalytics(result.user);
          } catch (e) {
            console.log('Авторизация метода signIn не произошла', e);
            setUser(null);
          }
        }
      );
    };

    initAuthStuff();
  }, []);

  if (!isLoading) {
    return children;
  }

  return <LoaderCat />;
};

const LoaderCat = () => {
  return (
    <Spinner>
      <img src={LogoCatFace} alt="Loading" style={{ width: '150px' }} />
    </Spinner>
  );
};

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
