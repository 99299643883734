import { Redirect, Route } from 'react-router-dom';
import {
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonViewDidEnter,
} from '@ionic/react';
// import { SafeArea } from '@capacitor-community/safe-area';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import { StatusBar } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/fonts.css';
import './theme/variables.css';
import './pages/MainScreen/styles/menu.css';
import './app.css';

import { sentenceGameData } from './pages/MainScreen/data/sentenceGameData';
import React, { useEffect } from 'react';
import { TBasicGameConfig, TTopic } from './pages/Game/types';

import { Game } from './pages/Game/Game';
import PubSub from 'pubsub-js';

// import { CaptureConsole } from '@sentry/integrations';
import { MainScreen } from './pages/MainScreen/MainScreen';
import styled from 'styled-components';
import { Intro } from './pages/Intro/Intro';
import { About } from './pages/About/About';
import { useRuntimeStore } from './initApp';
import { Stat } from './pages/Stat/Stat';
import { Capacitor } from '@capacitor/core';
import { Signup } from './pages/Signup/Signup';
import { Settings } from './pages/Settings/Settings';
import { Analytics } from './analytics';
import { H1Header } from './components/H1';
import { PageWrapper } from './components/PageWrapper';
import { EncouragingCatNew } from './pages/Game/components/EncouragingCat2/EncouragingCat';

import { IonAppStyled } from './Styles';

import { BRAND_COLOR } from './constants';
import { SubscriptionModal } from './components/SubscribeModal/SubscriptionModal';

const platform = Capacitor.getPlatform();

setupIonicReact({
  rippleEffect: false,
  mode: 'ios',
  animated: false,
  swipeBackEnabled: false,
});

const getRoutes = (gameData: TBasicGameConfig) => {
  return gameData
    .map((chapter) => {
      return chapter.topics.map((topic: TTopic) => (
        <Route
          path={`/learn/${topic.path}`}
          exact={true}
          key={topic.file}
          component={() => (
            <IonPage>
              <Game topic={topic} />
            </IonPage>
          )}
        ></Route>
      ));
    })
    .flat();
};

const App: React.FC = () => {
  const showTabs = useRuntimeStore((s) => s.showTabs);
  const user = useRuntimeStore((s) => s.user);

  const setStatusBarStyleLight = async () => {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }

    await StatusBar.setBackgroundColor({ color: BRAND_COLOR });
    // т.к. проблема только на Android с полоской внизу
    if (Capacitor.getPlatform() === 'android') {
      await NavigationBar.setColor({ color: BRAND_COLOR });
    }
  };

  useIonViewDidEnter(() => {
    // Добавил на всякий, т.к. если только у useEffect вызывать, то иногда ставило серый фон
    setStatusBarStyleLight();
  }, []);

  useEffect(() => {
    Analytics.trackAppOpened(platform);
    setStatusBarStyleLight();
  }, []);

  const getTabBarElement = () => {
    return document.querySelector('.js-tab-bar');
  };

  const setTabBarStyles = (style: string) => {
    const tabBarElement = getTabBarElement();
    if (tabBarElement instanceof HTMLElement) {
      tabBarElement.style.display = style;
    }
  };

  useEffect(() => {
    const showTabsToken = PubSub.subscribe('showTabs', () => {
      setTabBarStyles('flex');
    });

    const hideTabsToken = PubSub.subscribe('hideTabs', () => {
      setTabBarStyles('none');
    });

    return () => {
      PubSub.unsubscribe(showTabsToken);
      PubSub.unsubscribe(hideTabsToken);
    };
  }, []);

  const renderSignupRouter = () => {
    return (
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="*" exact={true} component={Signup}></Route>
        </IonRouterOutlet>
      </IonReactRouter>
    );
  };

  const renderMainRouter = () => {
    return (
      <>
        <SubscriptionModal />
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              {getRoutes(sentenceGameData)}
              <Route path="/intro">
                <IonPage>
                  <Intro />
                </IonPage>
              </Route>
              <Route path="/" exact={true}>
                <Redirect to="/intro" />
              </Route>
              <Route path="/learn" exact={true}>
                <IonPage>
                  <MainScreen />
                </IonPage>
              </Route>
              <Route path="/stat" exact={true}>
                <PageWrapper header={<H1Header>Статистика</H1Header>}>
                  <Stat />
                </PageWrapper>
              </Route>
              <Route path="/about" exact={true}>
                <PageWrapper header={<H1Header>О нас</H1Header>}>
                  <About />
                </PageWrapper>
              </Route>
              <Route path="/settings" exact={true}>
                <PageWrapper header={<H1Header>Настройки</H1Header>}>
                  <Settings />
                </PageWrapper>
              </Route>
            </IonRouterOutlet>
            <IonTabBar
              className={'js-tab-bar'}
              translucent
              slot="bottom"
              style={{
                '--background': 'var(--ion-background-color)',
                display: showTabs ? 'flex' : 'none',
                '--border': '2px solid var(--polako-black)',
                padding: 'var(--polako-padding)',
                paddingBottom: 'var(--polako-padding-bottom)',
              }}
            >
              <IonTabButton tab="learn" href="/learn">
                <IonLabelStyled>Учить</IonLabelStyled>
              </IonTabButton>

              <IonTabButton tab="stat" href="/stat">
                <IonLabelStyled>Статистика</IonLabelStyled>
              </IonTabButton>

              <IonTabButton tab="settings" href="/settings">
                <IonLabelStyled>Настройки</IonLabelStyled>
              </IonTabButton>

              <IonTabButton tab="about" href="/about">
                <IonLabelStyled>О нас</IonLabelStyled>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </>
    );
  };

  return (
    <IonAppStyled>
      <EncouragingCatNew />

      {user ? renderMainRouter() : renderSignupRouter()}
    </IonAppStyled>
  );
};

export default App;

const IonLabelStyled = styled(IonLabel)`
  font-size: 10px;
  font-weight: 700;
`;
