import { WordButton } from './WordButton';
import { clearWordString } from '../utils/cleadrWordString';
import React from 'react';
import styled from 'styled-components';
import { useGame } from '../hooks/useGame';

type TUseGameProps = ReturnType<typeof useGame>;

type TProps = {
  wordsToChooseFrom: TUseGameProps['wordsToChooseFrom'];
  alreadyPressedIndexes: TUseGameProps['alreadyPressedIndexes'];
  wordClickHandler: TUseGameProps['wordClickHandler'];
};

export const PuzzleButtons = ({
  wordsToChooseFrom,
  alreadyPressedIndexes,
  wordClickHandler,
}: TProps) => {
  return (
    <SPuzzle>
      {(wordsToChooseFrom || []).map((word, index) => {
        const isPressed = alreadyPressedIndexes.includes(index);
        const clickHandler = () => wordClickHandler(word, isPressed, index);

        return (
          <WordButton
            onPointerDown={clickHandler}
            isPressed={isPressed}
            key={index}
          >
            {clearWordString(word)}
          </WordButton>
        );
      })}
    </SPuzzle>
  );
};

const SPuzzle = styled.div`
  display: inline-flex;
  gap: 14px;
  flex-wrap: wrap;
  position: relative;
`;
