import { useRuntimeStore } from '../../../initApp';
import { useAsyncFn } from 'react-use';
import { Capacitor } from '@capacitor/core';
import { Billing } from '../../../biling/Billing';
import { useEffect } from 'react';

export const useAccessToAllPermissions = () => {
  const getManualSubscriptions = useRuntimeStore(
    (s) => s.getManualSubscriptions
  );
  const setAccessToAllPermission = useRuntimeStore(
    (s) => s.setAccessToAllPermission
  );

  const accessToAllPermission = useRuntimeStore((s) => s.accessToAllPermission);

  const tryToSetBillingAccessToAllPermissions = async (): Promise<boolean> => {
    if (!Capacitor.isNativePlatform()) return false;

    try {
      const permission = await Billing.getPermissionToUseFullApp();
      if (permission) {
        setAccessToAllPermission(permission);
        return true;
      } else {
        console.warn('Store billing permission not found');
      }
    } catch (error) {
      console.error('Error fetching billing access permissions:', error);
    }

    return false;
  };

  const tryToSetManualAccessToAllPermissions = async () => {
    const manualSubscriptions = await getManualSubscriptions();

    if (!manualSubscriptions) {
      console.warn('Manual access not found');
      return false;
    }

    return manualSubscriptions.some((subscription) => {
      const dateEndInMS = subscription.dateEnd * 1000;
      if (dateEndInMS > Date.now()) {
        setAccessToAllPermission({
          expireDate: dateEndInMS,
        });
        return true;
      }
      return false;
    });
  };

  const [fetchPermissionsState, fetchPermissions] = useAsyncFn(async () => {
    try {
      const billingSuccess = await tryToSetBillingAccessToAllPermissions();

      if (billingSuccess) {
        return true;
      }

      console.warn('Billing access not found');
      return await tryToSetManualAccessToAllPermissions();
    } catch (error) {
      console.error('Error fetching permissions:', error);
      return false;
    }
  }, []);

  useEffect(() => {
    fetchPermissions();
  }, []);

  return {
    isPermissionsLoading:
      fetchPermissionsState.value === undefined ||
      fetchPermissionsState.loading,
    permissionsLoadingError: fetchPermissionsState.error,
    accessToAllPermission,
  };
};
