import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from './store';
import * as amplitude from '@amplitude/analytics-browser';
import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { firebaseConfig } from './firebaseConfig';
import { Landing } from './pages/Landing/Landing';
import { AppWrapper } from './AppSentryWrapper';
import { initLandingGA } from './pages/Landing/initLandingGA';
import { initLandingYandexMetrika } from './pages/Landing/initLandingYandexMetrika';
import { initAppMetrics } from './initAppMetrics';
import { Capacitor } from '@capacitor/core';
import { Billing } from './biling/Billing';
import { Preferences } from '@capacitor/preferences';
import { TColor } from './types';

const USE_EMULATOR = false;

const container = document.getElementById('root');
const root = createRoot(container!);

const showLanding =
  location.hostname == 'polako.app' || import.meta.env.VITE_APP === 'landing';

export const useRuntimeStore = createStore();

const renderLanding = () => {
  initLandingGA();
  initLandingYandexMetrika();

  const params = new URLSearchParams(window.location.search);

  if (params.has('qr')) {
    const qr = params.get('qr');
    window.gtag('event', 'qr_' + qr, { debug_mode: true });
  }

  root.render(<Landing />);
};

const renderApp = async () => {
  initAppMetrics();
  initializeFirestore(initializeApp(firebaseConfig), {});
  const { value } = await Preferences.get({ key: 'color' });
  const color = value as TColor;

  if (
    Capacitor.getPlatform() === 'ios' ||
    Capacitor.getPlatform() === 'android'
  ) {
    await Billing.initializeApp();
  }

  root.render(<AppWrapper preferences={{ color: color || 'default' }} />);
};

if (showLanding) {
  renderLanding();
} else {
  renderApp();
}
