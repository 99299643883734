import { useIOSSetAccessToAllPermissions } from '../../hooks/billing/useIOSSetAccessToAllPermissions';
import { Billing } from '../../biling/Billing';
import { useThink } from '../../hooks/useThink';
import { useShowCatForMsNew } from '../Game/components/EncouragingCat2/useShowCatForMsNew';

export const useRestorePurchases = () => {
  const { setBillingAccessToAllPermissions } =
    useIOSSetAccessToAllPermissions();
  const { showThinking, hideThinking } = useThink();
  const { showCat } = useShowCatForMsNew();

  const restorePurchases = async () => {
    await showThinking();
    try {
      const permission = await Billing.restorePurchases();

      await hideThinking();

      if (permission) {
        setBillingAccessToAllPermissions(permission);

        showCat({
          text: `Все ок!`,
          textSub: 'Восстановлено!',
        });
        return;
      }

      showCat({
        text: 'Вроде бы нет активных подписок',
      });
    } catch (error) {
      if (typeof error === 'string') {
        showCat({
          text: error,
        });
      }

      if (error instanceof Error) {
        showCat({
          text: error.message,
        });
      }

      console.error('Error restoring purchases', error);
      await hideThinking();
    }
  };

  return { restorePurchases };
};
