import { css } from 'styled-components';

export const rootVariables = css`
  /** Ionic CSS Variables **/
  :root {
    --ion-font-family: 'Montserrat', sans-serif;
    /* POLAKO COLORs */
    --polako-shadow: 4px 3px 0px 0px rgba(0, 0, 0, 1);
    --polako-shadow-thick: 6px 4px 0px 0px rgba(0, 0, 0, 1);
    --polako-shadow-minimal: 2px 2px 0px 0px rgba(0, 0, 0, 1);
    --polako-border: 1px solid black;
    --polako-green: #80ff9d;
    --polako-black: #000;
    --polako-green: #80ff9d;
    --polako-white: #fff;
    --polako-red: #ff8080;
    --polako-yellow: #ff0;
    --polako-yellow-dark: #f8b91d;
    --polako-gray: #999999;
    --polako-purple: #b580ff;

    /** primary **/
    --ion-color-primary: #000;
    --ion-color-primary-rgb: 235, 68, 90;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #cf3c4f;
    --ion-color-primary-tint: #ed576b;

    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /* global */
    --ion-background-color: #ff6040;
    --ion-background-color-rgb: 255, 96, 64;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-color-step-50: #f25b3d;
    --ion-color-step-100: #e6563a;
    --ion-color-step-150: #d95236;
    --ion-color-step-200: #cc4d33;
    --ion-color-step-250: #bf4830;
    --ion-color-step-300: #b3432d;
    --ion-color-step-350: #a63e2a;
    --ion-color-step-400: #993a26;
    --ion-color-step-450: #8c3523;
    --ion-color-step-500: #803020;
    --ion-color-step-550: #732b1d;
    --ion-color-step-600: #66261a;
    --ion-color-step-650: #592216;
    --ion-color-step-700: #4d1d13;
    --ion-color-step-750: #401810;
    --ion-color-step-800: #33130d;
    --ion-color-step-850: #260e0a;
    --ion-color-step-900: #190a06;
    --ion-color-step-950: #0d0503;

    --ion-item-background: #000000;
    --ion-card-background: #1c1c1d;

    --polako-padding: 16px;
    --polako-padding-top: calc(
      var(--ion-safe-area-top) + var(--polako-padding)
    );
    --polako-padding-bottom: calc(
      var(--ion-safe-area-bottom) + var(--polako-padding)
    );
  }

  @media (prefers-color-scheme: dark) {
    body {
      --ion-background-color: #ff6040;
      --ion-background-color-rgb: 255, 96, 64;
    }

    .ios ion-modal {
      --ion-background-color: var(--ion-color-step-100);
      --ion-toolbar-background: var(--ion-color-step-150);
      --ion-toolbar-border-color: var(--ion-color-step-250);
    }
  }
`;
